// @ts-ignore
import React from "react"
import * as THREE from "three"
import WindowResize from "./lib/threex.windowresize"
// @ts-ignore
import WaterMaterial from "./lib/watermaterial"
// @ts-ignore
import waterNormalsSrc from "../images/waternormals.jpg"
// @ts-ignore
import grayNoiseMediumSrc from "../images/gray-noise-medium.png"
import { Color, Vector3 } from "three"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
// @ts-ignore
const islandGlbSrc = "island.glb"

class OceanScene extends React.Component {
  canvasElement: HTMLCanvasElement
  windowResizer: WindowResize

  componentDidMount() {
    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(
      5,
      document.body.clientWidth / window.innerHeight,
      10,
      5000,
    )

    const renderer = new THREE.WebGLRenderer({
      canvas: this.canvasElement,
    })

    renderer.setClearColor(new Color(document.body.style.backgroundColor), 1.0)

    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.BasicShadowMap

    renderer.setSize(document.body.clientWidth, window.innerHeight)
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1)
    directionalLight.castShadow = true
    directionalLight.shadow.camera.near = 1
    directionalLight.shadow.camera.far = 100
    directionalLight.shadow.bias = 0.0001
    directionalLight.position.set(10, 24, 30)
    scene.add(directionalLight)
    const textureLoader = new THREE.TextureLoader()
    const modelLoader = new GLTFLoader()
    const islandParent = new THREE.Object3D()
    islandParent.rotateY(-90)
    islandParent.position.add(new Vector3(-24, 0.2, -20))
    islandParent.castShadow = true
    islandParent.receiveShadow = true
    scene.add(islandParent)
    directionalLight.lookAt(islandParent.position)
    directionalLight.shadow.camera.left = directionalLight.shadow.camera.bottom = -35
    directionalLight.shadow.camera.right = directionalLight.shadow.camera.top = 35

    modelLoader.load(islandGlbSrc, (gltf) => {
      const islandObject = gltf.scene.children[0]
      const islandMaterial = new THREE.MeshLambertMaterial()
      islandParent.add(islandObject)
      islandParent.traverse((obj) => {
        obj.castShadow = true
        obj.receiveShadow = true
        let mesh = obj as THREE.Mesh
        mesh.material = islandMaterial
      })
    })
    const waterNormals = textureLoader.load(waterNormalsSrc)
    const noise = textureLoader.load(grayNoiseMediumSrc)
    noise.wrapS = noise.wrapT = waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping

    const waterObject = new WaterMaterial(renderer, camera, scene, {
      textureWidth: 512,
      textureHeight: 512,
      waterNormals: waterNormals,
      alpha: 1.0,
      sunDirection: directionalLight.position.normalize(),
      sunColor: 0xffffff,
      waterColor: 0xaaaaaa,
      noiseScale: 0.1,
      noiseSampler: noise,
      distortionScale: 0.1,
    })

    const planeObject = new THREE.Mesh(
      // new THREE.BoxGeometry(1,1,1),
      new THREE.PlaneBufferGeometry(200, 200, 400, 400),
      waterObject.material,
    )

    planeObject.add(waterObject)
    planeObject.rotation.x = -Math.PI * 0.5
    scene.add(planeObject)

    camera.position.y = 800
    camera.position.x = 800
    camera.lookAt(planeObject.position)

    const animate = function(time) {
      waterObject.material.uniforms.time.value = 0.002 * time
      waterObject.render()
      if (islandParent.children.length != 0) {
        let axisX = 0.03 * Math.cos(0.0005 * (time + 600))
        let axisZ = 0.04 * Math.sin(0.0003 * time) - 0.08
        let angle = -.0004 * Math.cos(0.00015 * time)
        islandParent.children[0].rotateOnAxis(new Vector3(axisX, 0.36, axisZ).normalize(), angle)
        islandParent.children[0].position.setY(0.4 * Math.sin(0.00088 * time))
      }

      renderer.render(scene, camera)
      requestAnimationFrame(animate)
    }

    this.windowResizer = new WindowResize(renderer, camera)

    animate(0)
  }

  componentWillUnmount() {
    this.windowResizer.destroy()
  }

  render() {
    return (
      <canvas ref={ref => this.canvasElement = ref}>
      </canvas>
    )
  }
}

export default OceanScene