import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"



const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
      query {
          globalMeltdown: file(relativePath: { eq: "globalmeltdown.png" }) {
              childImageSharp {
                  fluid(maxWidth: 325) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)


  return (
  <header
    style={{
      // background: `black`,
      marginBottom: `1.45rem`,
    }}
  >
  </header>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
