module.exports = [{
      plugin: require('/Users/bberman/Documents/GlobalMeltdown/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/bberman/Documents/GlobalMeltdown/src/layouts/layout.js"},
    },{
      plugin: require('/Users/bberman/Documents/GlobalMeltdown/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Oswald","Montserrat"]}},
    },{
      plugin: require('/Users/bberman/Documents/GlobalMeltdown/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/bberman/Documents/GlobalMeltdown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
