/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "../components/header"
import "../components/layout.scss"
import Background from "../components/background"
import UnityScene from "../components/unityscene"
import OceanScene from "../components/oceanscene"
import Odenkirk from "../components/odenkirk"

const Layout = ({ children }) => {
  return (
    <>
      <Background>
        <Odenkirk />
      </Background>
      <Header siteTitle="GlobalMeltdown"/>
      <div className="layout">
        <main>{children}</main>
        <footer>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
