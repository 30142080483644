import React from "react"
import Unity, { UnityContent } from "react-unity-webgl"

class UnityScene extends React.Component {
  constructor(props) {
    super(props)

    // Next up create a new Unity Content object to
    // initialise and define your WebGL build. The
    // paths are relative from your index file.

    this.unityContent = new UnityContent(
      "Build/84615ccec695ec058a2d84f64a54c31e.json",
      "Build/683251575ded36a9d3e246642d06f9d3.js",
    )
  }

  render() {
    return (<>
      <Unity width="100%" height="100%" unityContent={this.unityContent}/>
    </>)
  }
}

export default UnityScene