import * as THREE from "three"

/**
 * Update renderer and camera when the window is resized
 *
 * @param {Object} renderer the renderer to update
 * @param camera
 * @param {Function} dimension callback for renderer size
 */
class WindowResize {
  trigger: Function
  destroy: Function

  constructor(renderer: THREE.Renderer, camera: THREE.PerspectiveCamera, dimension?) {
    dimension = dimension || function() {
      return { width: document.body.clientWidth, height: window.innerHeight }
    }
    const callback = function() {
      // fetch target renderer size
      const rendererSize = dimension()
      // notify the renderer of the size change
      renderer.setSize(rendererSize.width, rendererSize.height)
      // update the camera
      camera.aspect = rendererSize.width / rendererSize.height
      camera.updateProjectionMatrix()
    }

    this.trigger = function() {
      callback()
    }

    window.addEventListener("resize", callback, false)
    this.destroy = function() {
      window.removeEventListener("resize", callback)
    }
  }
}

export default WindowResize