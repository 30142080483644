import React from "react"

const Background = ({ children }) => {
  return (
    <React.Fragment>
      <div
        className="background"
        style={{
        position: `fixed`,
        zIndex: -1,
        top: 0,
        left: 0,
        height: `100%`,
        width: `100%`,
        overflowY: `hidden`,
      }}>{children}</div>
    </React.Fragment>
  )
}

export default Background