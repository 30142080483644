import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"



const Odenkirk = () => {
  const data = useStaticQuery(graphql`
      query {
          odenkirk: file(relativePath: { eq: "errol058e.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1920) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)

  return (<Img fluid={data.odenkirk.childImageSharp.fluid} style={{
    "width": "100%",
    "height": "100%"
  }}/>)
}

export default Odenkirk